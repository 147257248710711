<template>
    <div class="search-word-item">
        <span v-tooltip="$root.l10n('click_to_edit_search_word')"
              :contenteditable="true"
              @keydown.enter.prevent="enter"
              @blur="enter"
              @paste.prevent="onPaste"
        >{{ modelValue }}</span>
        <IconLight icon="times" @click="remove"/>
    </div>
</template>

<script>
import IconLight from "@/components/utility/IconLight";
import {directive as ClickOutside} from "click-outside-vue3";
export default {
    name: "SearchWord",
    components: {IconLight},
    props: {
        modelValue: String
    },
    directives: {
        ClickOutside
    },
    methods: {
        remove() {
            this.$emit('remove')
        },
        enter(event) {
            const text = event.target.innerHTML
            if (text === '') {
                this.remove()
            } else if (this.modelValue !== text) {
                this.$emit('update:modelValue', text)
            }
        },
        onPaste (event) {
            const currentText = event.target.innerHTML
            const clipBoard = event.clipboardData || window.clipboardData
            const pastedText = clipBoard.getData('text/plain')
            event.target.innerHTML = currentText + pastedText
        }
    }
}
</script>