const Collection = require('./collection')

class Right {
    /**
     * @param {Object} data
     */
    constructor (data) {
        this.id = null

        if (data && Object.keys(data).length > 0) this.set(data)
    }
    /**
     * @param {Object} data
     */
    set (data) {
        this.id = data.Name || data.name || null
    }
}

class RightCollection extends Collection {
    /**
     * @param {Object} element
     * @return {Right}
     */
    castObject(element) {
        return (element instanceof Right) ? element : new Right(element)
    }
}

module.exports = {
    Right,
    RightCollection
}