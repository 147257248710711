<template>
    <div
        class="overlay-wrapper"
        :class="!tabSwitch || reverseTabs ? 'tabs-inline' : null"
        v-bind="$root.addTestLabel('overlay-wrapper')"
    >
        <div class="popup-wrapper" v-bind="$root.addTestLabel('modal-wrapper')">
            <div class="popup-container d-flex" v-if="error">
                <div class="popup-main">
                    <ModalContentHeader
                        :backTitle="layerBack"
                        @close="closeHandler"
                        v-bind="$root.addTestLabel('modal-main-header')"
                    />
                    <div class="popup-body scrollable"><ErrorPage :error="error"/></div>
                </div>
            </div>
            <div class="popup-container d-flex" v-else>
                <ModalSidebar
                    v-if="actions.length > 0"
                    :actions="actions"
                    :activeAction="id"
                    :title="title"
                    :subtitle="subtitle || (contentArgs.id ? 'ID: ' + contentArgs.id : '')"
                    :imagePath="imagePath"
                    @changeActiveContent="changeActiveContent"
                    v-bind="$root.addTestLabel('modal-sidebar')"
                />
                <div class="popup-main" v-bind="$root.addTestLabel('modal-main')">
                    <ModalContentHeader
                        :headline="layerHeadline"
                        :backTitle="layerBack"
                        :languageSet="languageSet"
                        :tabSwitch="tabSwitch"
                        @toggleLayout="toggleLayoutTab"
                        @back="changeLayer(false)"
                        @close="closeHandler"
                        v-model:locale="locale"
                        v-bind="$root.addTestLabel('modal-main-header')"
                    />
                    <div class="popup-body" v-bind="$root.addTestLabel('modal-main-body')" ref="popupBody">
                        <component
                            v-if="component"
                            :is="component"
                            v-bind="contentArgs"
                            v-on="contentEvents"
                            @close="closeHandler"
                            @changeLayer="changeLayer"
                            @changeLanguageSet="changeLanguageSet"
                            @sub-title="setSubtitle"
                            :locale="locale"
                        />
                        <div v-else-if="message">{{ message }}</div>
                        <div v-else >action {{ id || "none" }} / component: {{ component || "undefined"}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {markRaw} from 'vue'
import ModalSidebar from './ModalSidebar.vue'
import ModalContentHeader from './ModalContentHeader.vue'
import ErrorPage from '../../layout/ErrorPage'
import {isControlKeyPressed} from '../../../lib/utility'
const localStorage = require('../../../lib/localStorage')

export default {
    name: "Modal",
    components: {
        ErrorPage,
        ModalSidebar,
        ModalContentHeader
    },
    props: {
        id: [Number, String],
        headline: String,
        content: [Object, String],
        actions: {
            type: Array,
            default() { return [] }
        }
    },
    data() {
        const tabLayout = localStorage.get('tabLayout')
        const closeOnMode = parseInt(this.$root.settings.DIALOG_CLOSE_ON, 10) || 0

        console.log('actions ' , this.actions)

        return {
            error: null,
            layers: [{title: this.headline, back: null}],
            allowCloseOnEsc: closeOnMode === 2 || closeOnMode === 4,
            allowCloseOnOutsideClick: closeOnMode === 3 || closeOnMode === 4,
            reverseTabs: tabLayout === null ? false : !!tabLayout,
            languageSet: null,
            locale: null,
            subtitle: null,
            observer: null,
            tabSwitch: false
        }
    },
    computed: {
        modalData() {
            return this.data
        },
        message() {
            return typeof this.content === 'string' ? this.content : null
        },
        component() {
            return this.content && this.content.component || null
        },
        title () {
            return this.content?.args?.title
        },
        imagePath () {
            return this.content?.args?.imagePath
        },
        contentArgs() {
            const args = Object.assign({}, this.content?.args || {})
            delete args.on
            delete args.title
            delete args.imagePath
            return args
        },
        contentEvents () {
            return this.content?.args?.on || {}
        },
        layerHeadline() {
            return this.layers[this.layers.length-1]?.title || this.headline
        },
        layerBack() {
            return this.layers[this.layers.length-1]?.back || null
        }
    },
    emits: {
        change: null,
        close: null
    },
    methods: {
        setSubtitle(title) {
            this.subtitle = title
        },
        changeActiveContent(modal) {
            this.$emit('change', modal, this.actions)
        },
        closeHandler() {
            this.$emit('close')
            //this.$destroy()
        },
        closeOnOutsideClick(e) {
            if (this.allowCloseOnOutsideClick
                && (this.$el === e.target)
            ) this.closeHandler()
        },
        closeOnEsc(e) {
            const controlTags = ['input', 'select', 'textarea']
            const controlEl = ['fr-element fr-view']

            if (this.allowCloseOnEsc
                && e.keyCode === 27
                && !isControlKeyPressed(e)
                && controlTags.indexOf(e.target.tagName.toLowerCase()) === -1
                && controlEl.indexOf(e.target.className.toLowerCase()) === -1
            ) this.closeHandler()
        },
        /**
         * @param {string?} headline - Some string add layer, FALSE unset last layer, NULL reset to 0 layer
         * @param {function?} [backBtn]
         * @param {string?} [backTitle]
         *
         * @sample: changeLayer('layer 1', callback) - create new layer and show back button
         * @sample: changeLayer('layer 2', callback, 'revert') - create new layer and show back button with title 'revert'
         * @sample: changeLayer('layer 3') - create new layer and show back button without back btn
         * @sample: changeLayer(false) - unset last layer, goes to "layer 2"
         * @sample: changeLayer(null) - unset all layers, goes to base
         * @sample: changeLayer(null, null) - unset all (or last) layers, without calling callback, goes to base
         */
        changeLayer(headline, backBtn, backTitle){
            if (headline === null && this.layers.length > 1) {
                if (null === backBtn || false !== this.layers[1].callback())
                    this.layers.splice(1)
            } else if (!headline && this.layers.length > 1) {
                if (null === backBtn || false !== this.layers[this.layers.length-1].callback())
                    this.layers.pop()
            } else if (headline) {
                this.layers.push({
                    title: headline,
                    back: typeof backBtn === 'function' ? backTitle || true : false,
                    callback: typeof backBtn === 'function' ? backBtn : () => true
                })
            }
        },
        changeLanguageSet(languageSet) {
            this.languageSet = languageSet
        },
        toggleLayoutTab() {
            this.reverseTabs = !this.reverseTabs
        },
        checkTabs(tabCount){
            tabCount > 1 ? this.tabSwitch = true : this.tabSwitch = false
        }
    },
    created() {
        document.addEventListener('keydown', this.closeOnEsc)
        document.addEventListener('click', this.closeOnOutsideClick)
    },
    beforeUnmount() {
        console.log('modal beforeUnmount')
    },
    mounted () {
        let target = this.$refs.popupBody
        let self = this

        function callback () {
            let tabCount = target?.getElementsByClassName("tab").length
            self.checkTabs(tabCount)
        }
        this.observer = markRaw(new MutationObserver(callback))
        let config = { childList: true, subtree: true };
        this.observer.observe(target, config);
        callback()
    },
    unmounted() {
        document.removeEventListener('keydown', this.closeOnEsc)
        document.removeEventListener('click', this.closeOnOutsideClick)
        this.observer.disconnect();

        console.log('modal unmounted')
    },
    errorCaptured(err, vm, info){
        console.error('errorHandler', err, 'vm:', vm, 'info:', info);
        this.error = err
        return false;
    },
    watch: {
        content(){
            this.layers = [{title: this.headline, back: null}]
            this.subtitle = null
            this.error = null
        },
        reverseTabs(state) {
            localStorage.set('tabLayout', state)
        }
    }
}
</script>