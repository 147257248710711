<template>
    <label :class="[type === 'radio' ? 'radiobox-container' : 'checkingbox-container', additionalType]">
        <input
            :type="type"
            :name="name"
            :data-name="dataName || name"
            :value="value"
            :checked="checked"
            :disabled="disabled"
            @click.stop="$emit('click', $event)"
            @input="$emit('update:modelValue', $event.target.checked ? value : nonValue)"
        />
        <span class="checkmark"><IconSolid v-if="type === 'checkbox' || additionalType === 'choicebox-confirm'" :icon="checked === 2 ? 'minus' : 'check'"/><IconSolid v-if="additionalType === 'choicebox-deny'" icon="times"/></span>
        <span class="text-label" :style="{ backgroundColor: color }" v-if="labeled"><slot/></span>
    </label>
</template>

<script>
import IconSolid from '../../utility/IconSolid.vue'

export default {
    name: "ChoiceBox",
    components: { IconSolid },
    emits: {click: null, 'update:modelValue': null},
    props: {
        id: [Number, String],
        name: String,
        color: String,
        value: {
            type: [Boolean, Number, String],
            default: true
        },
        checked: [Number, Boolean],
        disabled: Boolean,
        dataName: String,
        type: {
            type: String,
            default: 'checkbox'
            //checkbox, radio
        },
        additionalType: String
    },
    computed: {
        labeled() {
            return !!this.$slots.default
        },
        nonValue() {
            switch(typeof this.value) {
                case 'boolean': return false
                case 'number' : return 0
                default: return ''
            }
        }
    }
}
</script>