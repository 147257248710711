<template>
    <div class="preview-container" :class="previewMode === 'user' ? 'user-preview' : ''" :style="{ backgroundImage: file ? `url(${path+file})` : 'none'}">
        <FontAwesomeIcon v-if="!file && icon"
            :icon="icon"
            class="i"
        />
        <slot v-else-if="!file" />
    </div>
</template>

<script>
import FontAwesomeIcon from '../../../../lib/fontawesome'
export default {
    name: 'ItemPreview',
    components: {FontAwesomeIcon},
    props: {
        file: String,
        path: {
            type: String,
            default: ''
        },
        icon: {
            type: Array,
            default() { return ['fal', 'image']}
        },
        previewMode: String
    }
}
</script>