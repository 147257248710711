<template>
    <AutoCompleteInput
        class="user-input"
        :modelValue="modelValue"
        :label="label"
        :inline="inline"
        :multiple="multiple"
        :disabled="disabled"
        :required="required"
        :readonly="readonly"
        :preload="preload"
        :preview="imageBasePath !== null"
        previewMode="user"
        :icon="icon"
        :imageBasePath="imageBasePath"
        :requestURL="target"
        :requestFilter="filter"
        :excludedId="excludedId"
        :parseOption="parseEntity"
        :searchText="parseSearch"
        :actions="actions"
        :errors="errors"
        :default-resettable="defaultResettable"
        @update:modelValue="$emit('update:modelValue', $event)"
        @open="$emit('open')"
        ref="autoCompleteInput"
    >
        <slot />
        <template #info><slot name="info" /></template>
        <template #actions="props"><slot name="actions" v-bind="props" /></template>
        <template #before-input><slot name="before-input" /></template>
        <template #after-each-selected="props">
            <slot name="after-each-selected" v-bind="props" />
        </template>
    </AutoCompleteInput>
</template>

<script>
import AutoCompleteInput from "./AutoCompleteInput"
export default {
    name: "UserInput",
    components: {
        AutoCompleteInput
    },
    props: {
        modelValue: [Array, Object, String],
        label: String,
        inline: Boolean,
        multiple: Boolean,
        infoView: Boolean,
        disabled: Boolean,
        required: Boolean,
        readonly: Boolean,
        preload: Boolean,
        filter: Object,
        excludedId: [Array, String, Number],
        errors: [Array, Object, String],
        defaultResettable: Boolean
    },
    data() {
        return {
            target: this.$root.getRoutePath('user.autocomplete'),
            imageBasePath: this.$root.CONSTANTS.IMAGE_PATHS.USER,
            icon: ['fal', 'user'],
        }
    },
    computed: {
        actions() {
            return [
                this.infoView ? { id: 'info', icon: 'info', type: 'user' } : null,
                this.readonly ? null : { id: 'detach', icon: 'times' }
            ].filter(v => !!v)
        }
    },
    methods: {
        parseEntity(user) {
            return parseEntity(user)
        },
        parseSearch(option) {
            return 'ID: ' + option.value + '; ' + option.title + ' ' + option.subTitle
        }
    }
}

const parseEntity = function (user) {
    return {value: user.Id, title: user.Name, subTitle: user.Email, imagePath: user.ImagePath, __data: user}
}

export {
    parseEntity
}
</script>