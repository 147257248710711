<template>
    <div id="headerToolbar">
        <div id="userMenuWrapper" class="dropdown-menu-container float-right" v-click-outside="hideDropdown">
            <div
                id="user-menu-btn"
                class="dropdown-menu-btn btn-big"
                v-tooltip="$root.l10n('open_user_menu')"
                @click="showDropdown = !showDropdown"
                v-bind="$root.addTestLabel('user-menu-btn')"
            >
                <div id="userPic" :style="'background-image: url(' + userPic + ')'"></div>
            </div>
            <UserMenu v-if="showDropdown" @clickMenuItem="hideDropdown"/>
        </div>
        <UserSimulate v-if="simulateEnabled" />
        <NotificationDropdown />
    </div>
</template>

<script>
import {directive as ClickOutside} from 'click-outside-vue3'
import UserSimulate from "./UserSimulate"
import UserMenu from "./UserMenu.vue";
import NotificationDropdown from "./NotificationDropdown.vue";

const {User} = require('../../../lib/models/user')

export default {
    name: "Toolbar",
    components: { UserMenu, UserSimulate, NotificationDropdown },
    data: function(){
        return {
            showDropdown: false
        }
    },
    computed: {
        userPic () {
            const {imagePath, gender} = this.$root.user
            const basePath = process.env.BASE_URL
            const assetPath = 'assets/manikin/'
            if (this.$root.user.id === 1) {
                return basePath + 'assets/favicon/' + imagePath //favicon_300x300.png
            } else if (imagePath) {
                return basePath + 'storage/users/' + imagePath
            } else if (gender === User.GENDER_MALE) {
                return basePath + assetPath + 'male.svg'
            } else if (gender === User.GENDER_FEMALE) {
                return basePath + assetPath + 'female.svg'
            } else {
                return basePath + assetPath + 'unknown.svg'
            }
        },
        simulateEnabled () {
            return this.$root.simulator
                || this.$root.checkRight('SIMULATE_USER')
                || this.$root.absences?.length > 0
        }
    },
    directives: {
        ClickOutside
    },
    methods: {
        closeDropdownsOnEsc: function(e) {
            if (e.keyCode === 27) this.hideDropdown()
        },
        hideDropdown(){
            this.showDropdown = false
        }
    },
    mounted() {
        document.addEventListener('keydown', this.closeDropdownsOnEsc)
    },
    unmounted() {
        document.removeEventListener('keydown', this.closeDropdownsOnEsc)
    },
}
</script>