<template>
    <Dropdown
        id="userSimulate"
        :class="simulating ? 'active' : null"
        class="bg-white float-right"
        :headline="$root.l10n('simulate_user')"
        :size="ddSize.medium"
        :icon="'user-group'"
    >
        <template #toggler="{toggle}">
            <div
                class="dropdown-menu-btn btn-std btn-icon-only"
                v-tooltip="$root.l10n('substitute_user')"
                @click="() => { toggle() && onOpen() }"
                v-bind="$root.addTestLabel('user-simulate-btn')"
            >
                <IconThin icon="user-group" />
            </div>
        </template>
        <template #default>
                <div v-if="simulatingAllowed" class="dropdown-menu-section">
                    <UserInput v-model="sim" inline :excluded-id="excludes" @update:modelValue="changedSim" ref="userInput"/>
                    <ButtonStd v-if="sim && !simulating" primary class="btn-medium btn-blue"  @click="() => start(sim.value)">{{$root.l10n('simulating_start')}}</ButtonStd>
                </div>
                <div v-if="absences.length > 0" class="dropdown-menu-section">
                    <SelectInput
                        :label="$root.l10n('substitute_user')"
                        :options="absences"
                        v-model="sub"
                        @update:modelValue="changedSub"
                    />
                    <ButtonStd v-if="sub && !simulating" primary class="btn-medium btn-blue" @click="() => start(sub)">{{$root.l10n('simulating_start')}}</ButtonStd>
                </div>
                <div v-if="simulating" class="dropdown-menu-section">
                    <p class="text-alert alert-danger">{{$root.l10n('simulating_mode_notice', sName)}}</p>
                    <ButtonStd primary class="btn-medium btn-blue"  @click="stop">{{$root.l10n('simulating_stop')}}</ButtonStd>
                </div>
        </template>
    </Dropdown>
</template>

<script>
import UserInput from '../../forms/inputs/UserInput'
import SelectInput from '../../forms/inputs/SelectInput'
import ButtonStd from '../../forms/ButtonStd'
import Dropdown, {ddSize} from '../../utility/Dropdown'
import IconThin from '../../utility/IconThin'
const ajax = require('../../../lib/ajax')

export default {
    name: 'UserSimulate',
    components: {
        Dropdown,
        IconThin,
        ButtonStd,
        UserInput,
        SelectInput,
    },
    setup() {
        return {
            ddSize
        }
    },
    data() {
        return {
            sim: this.initSim(),
            sub: this.initSub()
        }
    },
    computed: {
        simulating() {
            return !!this.$root.simulator && !this.sub
        },
        substituting() {
            return !!this.$root.simulator && !this.sim
        },
        excludes() {
            return this.$root.simulator?.id || this.$root.user.id
        },
        absences() {
            return (this.$root.absences || []).map(a => {
                return {value: a.id, title: a.name, __data: a}
            })
        },
        sName() {
            return this.sim?.title ||
                this.absences.find(a => a.value === this.sub)?.title
        },
        simulatingAllowed() {
            return this.$root.checkRight('SIMULATE_USER')
                || (this.simulating && !this.absences.some(a => a.value === this.$root.user.id))
        }
    },
    methods: {
        /*changed() {
            if (this.simulating && !this.sim && !this.sub)
                this.stop()
        },*/
        changedSim(value) {
            if (this.simulating && !value?.length) this.stop()
        },
        changedSub(value) {
            if (value) this.sim = null
            else this.sim = this.initSim()
        },
        start(value) {
            this.switchUser(value)
        },
        stop() {
            this.switchUser(this.$root.simulator.id)
        },
        switchUser(uid) {
            return ajax.json(this.$root.getRoutePath('switchUser', uid)).then(({response}) => {
                return this.$root.setEnvironment().then(() => {
                    this.sim = this.initSim()
                    this.sub = this.initSub()
                    this.$root.currentRoute = this.$root.findRouteByPath(response.location)
                })
            }).catch(this.$root.throwError)
        },
        initSim() {
            return this.$root.simulator && !this.$root.absences?.some(a => a.id === this.$root.user.id) ?
                {value: this.$root.user.id, title: this.$root.user.name, data: this.$root.user} : null
        },
        initSub() {
            return this.$root.simulator && this.$root.absences?.some(a => a.id === this.$root.user.id)
                ? {value: this.$root.user.id, title: this.$root.user.name, data: this.$root.user} : null
        },
        onOpen() {
            if (this.sim || this.sub) return
            this.$nextTick(() => {
                this.$refs.userInput?.$refs.autoCompleteInput?.$refs.input?.$el.focus()
            })
        }
    }
};
</script>