<template>
    <div :class="localeCSS" id="root">
        <LoadingBar :percentage="loadingPercentage" />
        <SplashScreen v-if="isSplashScreen" :args="viewArgs" />
        <template v-else>
           <div id="wrapper" v-if="user && insideComponent">
                <Header></Header>
                <div id="mainWrapper" class="d-flex flex-row">
                    <Sidebar :naviItems="topLevelMenu" />
                    <component
                    :state="0"
                        :is="viewComponent"
                        :action="viewAction"
                        :args="viewArgs"
                        ref="viewComponent"
                    />
                </div>
            </div>
            <AuthController v-else
                :action="viewAction"
                :args="viewArgs"
            />
            <Modal
                v-if="modalInfo.show"
                :id="modalInfo.id"
                :actions="modalInfo.actions"
                v-bind="modalInfo.args"
                :uri="undefined"
                @close="hideModal"
                @change="(modal, actions) => showModal(modal.id, modal.data, actions)"
            />
        </template>
    </div>
</template>

<script>
import { computed, defineAsyncComponent } from 'vue'
import useActivityPolling from './components/utility/activityPolling.vue'

const { Routing, Route } = require('./lib/routing')
const { User } = require('./lib/models/user')
const { LanguageCollection } = require('./lib/models/language')
const { Actions } = require('./lib/actions')
const { SettingCollection, associateSetting } = require('./lib/models/setting')
const { RightCollection } = require('./lib/models/right')
const { getTranslationFromDictionary } = require('./lib/utility')
const CONSTANTS = require('./lib/constants')

import Header from './components/layout/header/Header'
import Sidebar from './components/layout/sidebar/Sidebar'
import SplashScreen from './components/layout/SplashScreen'
import LoadingBar from './components/layout/LoadingBar'
import ErrorController from './components/controllers/ErrorController'
import Modal from './components/layout/modal/Modal'
import EventBus from "./lib/helpers/EventBus"
import ajax from "./lib/ajax.js"

export default {
    name: 'App',
    components: {
        Header,
        Sidebar,
        AuthController: defineAsyncComponent(() => import('./components/controllers/AuthController')),
        CRMController: defineAsyncComponent(() => import('./components/controllers/CRMController')),
        DashboardController: defineAsyncComponent(() => import('./components/controllers/DashboardController')),
        EntityController: defineAsyncComponent(() => import('./components/controllers/EntityController')),
        FileController: defineAsyncComponent(() => import('./components/controllers/FileController')),
        ListPresetController: defineAsyncComponent(() => import('./components/controllers/ListPresetController')),
        MediaController: defineAsyncComponent(() => import('./components/controllers/MediaController')),
        MediaSharedLinkController: defineAsyncComponent(() => import('./components/controllers/MediaSharedLinkController')),
        ObjectCategoryController: defineAsyncComponent(() => import('./components/controllers/ObjectCategoryController')),
        NewsletterController: defineAsyncComponent(() => import('./components/controllers/NewsletterController')),
        ProfileController: defineAsyncComponent(() => import('./components/controllers/ProfileController')),
        SettingController: defineAsyncComponent(() => import('./components/controllers/SettingController')),
        SocialController: defineAsyncComponent(() => import('./components/controllers/SocialController')),
        SocialMediaController: defineAsyncComponent(() => import('./components/controllers/SocialMediaController')),
        GDPRController: defineAsyncComponent(() => import('./components/controllers/GDPRController')),
        ErrorController,
        SplashScreen,
        LoadingBar,
        Modal
    },
    setup() {
        const polling = useActivityPolling()
        return {
            CONSTANTS: CONSTANTS,
            version: '5.?',
            settingControllers: [
                'UserController',
                'RoleController',
                'GroupController',
                'ModuleController',
                'AttributeController',
                'CategoryController',
                'VariantController',
                'ConfigController',
                'PrivacyController',
                'SearchPresetController',
                'LdapController',
                'FtpController',
                'IccController'
            ],
            routing: new Routing(),
            polling: polling,
            dict: {global: {}},
            actions: []
        }
    },
    data() {
        return {
            currentRoute: null,
            topLevelMenu: [],
            customLinks: [],
            systemLocale: null,
            languages: new LanguageCollection(
                {locale: 'de_DE', name: 'Deutsch (DE)'},
                {locale: 'en_GB', name: 'English (EN)'}
            ),
            loadingPercentage: 0,
            user: null,
            modalInfo: {
                show: false,
                type: '',
                content: null,
                args: {},
                actions: []
            },
            legals: [],
            rights: [],
            settings: [],
            texts: {},
            GDPRExpired: null,
            simulator: null,
            absences: [],
            debug: false
        }
    },
    computed: {
        viewComponent() {
            const controller = this.redirectRouteController(this.currentRoute)
            if (this.user && controller === 'AuthController') {
                const route = this.topLevelMenu[0]?.route || 'profile.survey'
                this.goToRoute(this.getRoute(route))
                return this.$options.components.SplashScreen
            } else if (typeof this.$options.components[controller] === 'undefined') {
                const error = new Error(controller ? 'Controller not found: ' + controller : 'Unknown controller')
                this.setLoadingPercentage(100)
                this.errorScreen(error)
                return this.$options.components.ErrorController
            } else return this.$options.components[controller]
        },
        viewAction() {
            if (!this.user && this.currentRoute.controller !== 'AuthController') return 'start'
            else return this.currentRoute.action || 'start'
        },
        viewArgs() {
            return this.currentRoute.args || {}
        },
        localeCSS() {
            return this.systemLocale
        },
        insideComponent() {
            return this.viewComponent !== this.$options.components.AuthController
        },
        isSplashScreen() {
            return this.viewComponent === this.$options.components.SplashScreen
        },
        /**
         * @returns {?object}
         * @see topLevelMenu
         */
        currentModule() {
            return this.topLevelMenu.find(v => this.currentRoute.path.indexOf(v.href) === 0)
        }
    },
    methods: {
        /**
         * @param {Route} route
         * @returns {string}
         */
        redirectRouteController(route) {
            if (route?.name?.indexOf('gdpr.purposeusage.') === 0) return  'SettingController'
            else return this.mapController(route?.controller)
        },
        /**
         * @param {string} controller
         * @returns {string|*}
         */
        mapController(controller) {
            return (this.settingControllers.includes(controller)) ? 'SettingController' : controller
        },

        // -- ROUTING
        /**
         * @param {string} path
         */
        findRouteByPath(path) {
            // hook: avoid Engine::start
            return (path === '/' || path === this.routing.base) && this.routing.findByName('home')
                || this.routing.findByPath(path)?.clone() || this.routing.getUnknownRoute()
        },
        /**
         * @param {string} routeName
         * @returns {Route}
         */
        getRoute(routeName) {
            return this.routing.findByName(routeName)
        },
        /**
         * @param {string} routeName
         * @param {...any} args
         * @returns {string}
         */
        getRoutePath(routeName, ...args) {
            const route = this.routing.findByName(routeName)
            return route ? route.preparePath(...args) : '/#'
        },
        /**
         * @param {Route} route
         * @param {...any} args
         * @returns {string}
         */
        setRoute(route, ...args) {
            this.currentRoute = route
            return this.currentRoute.prepare(...args).argsPath
        },
        /**
         * @param {string} uri
         * @param {...any} args
         */
        goToRoute(uri, ...args) {
            let target = uri;
            if (uri instanceof Route) {
                this.currentRoute = uri
                target = this.currentRoute.prepare(...args).argsPath
            } else {
                const v = this.routing.findByPath(uri)
                this.currentRoute = v ? v.clone() : new Route({name: "none"})
            }
            this.changeURI(this.currentRoute)
        },
        /**
         * @param {Route} route
         * @param {Boolean} modal
         */
        changeURI(route, modal = false) {
            if (route.argsPath !== document.location.pathname) {
                window.history.pushState(
                    {route: route.name, args: JSON.stringify(route.args), modal},
                    document.title,
                    route.argsPath
                )
            } else {
                window.history.replaceState(
                    {route: route.name, args: JSON.stringify(route.args), modal},
                    document.title,
                    route.argsPath + document.location.hash
                )
            }
        },
        /**
         * @param {Event} event
         */
        follow(event) {
            const target = event.currentTarget || event.target
            const uri = target.getAttribute('href')
            if (uri) this.goToRoute(uri)
            event.preventDefault()
        },
        // --- END OF ROUTING

        setEnvironment() {
            this.splashScreen()
            this.currentRoute.args = {progress: 1}
            this.loadingPercentage = 1

            return ajax.json(this.routing.base + 'environment/').then(({response}) => {
                if (response.User) this.user = new User(response.User)
                this.simulator = response.Simulator ? new User(response.Simulator) : null
                this.absences = response.Absences ? response.Absences.map(u => new User({
                    Id: u.UserId, FirstName: u.FirstName, LastName: u.LastName
                })) : []
                return response;
            }).then(response => {
                this.version = response.Version
                this.debug = response.Debug || false

                if (response.Actions) this.actions = new Actions(response.Actions);
                this.systemLocale = response.Locale || this.systemLocale || 'de_DE'
                if (response.Languages) {
                    this.languages = new LanguageCollection(...(response.Languages || []))
                }
                return response;
            }).then(response => {
                this.routing.push(...response.Routes)
                Object.assign(this.currentRoute.args, {
                    message: 'Routings loaded',
                    progress: this.loadingPercentage = this.currentRoute.args.progress + 29
                })
                return response;
            }).then(response => {
                this.setSettings(response.Settings || [])
                this.rights = new RightCollection(response.Rights || [])
                this.customLinks = response.CustomLinks || []
                this.legals = response.Legals || []
                this.GDPRExpired = response.GDPRExpired || null
                this.texts = {
                    welcomeDashboard: response.WelcomeDashboard,
                    welcomeLogin: response.WelcomeLogin
                }

                this.dict = {global: {}}
                return ajax.json(response.Dictionary)
                    .then(({response}) => {
                        this.dict.global = response
                        Object.assign(this.currentRoute.args, {
                            message: 'Dictionary loaded',
                            progress: this.loadingPercentage = this.currentRoute.args.progress + 20
                        });
                        return 1;
                    })
            }).then(() => {
                this.setNaviItems()
                if (this.user) this.polling.init(this.getRoutePath('engine.polling'), this.version)
                else this.polling.stop()

                this.currentRoute = this.findRouteByPath(document.location.pathname)
                return true
            }).catch(e => {
                this.currentRoute.args = {
                    message: e.message,
                    progress: this.loadingPercentage = 100,
                    failed: e.message
                }
                return false
            });
        },
        /** */
        splashScreen() {
            this.currentRoute = new Route({name: 'splashscreen', controller: 'SplashScreen', action: 'start'})
        },
        /**
         * @param {Error|String} error
         * @param {any?} additional
         * @param {Error|XMLHttpRequest|Object?} additional
         */
        errorScreen(error, additional) {
            this.hideModal()
            this.currentRoute = {
                name: 'error',
                controller: 'ErrorController',
                action: error instanceof Error && error.constructor.name || 'Error',
                path: this.currentRoute.path,
                args: {
                    message: (error instanceof Error ? error.message : error) || this.l10n('unknown_error'),
                    debug: additional || (error instanceof Error ? error.stack : error)
                }
            }
        },
        throwError(error, additional) {
            this.errorScreen(error, additional)
            throw error
        },
        /**
         * @param {string[]} dictionary
         * @returns {Promise.<boolean>}
         */
        loadDictionary(...dictionary) {
            const loaded = []
            for (let d of dictionary) {
                if (!this.dict[d]) {
                    let routePath = this.getRoutePath('dict', d)
                    loaded.push(
                        ajax.json(routePath).then(({response}) => this.dict[d] = response)
                    )
                }
            }

            return Promise.all(loaded).then(() => true).catch(e => {
                this.throwError('failed to load dictionary.', e)
            })
        },
        /**
         * @param {string} key
         * @param {any[]} params
         */
        l10n(key, ...params) {
            const str = getTranslationFromDictionary(this.dict, key, ...params)
            return str === null ? (this.debug ? 'KEY:' : '') + key : str
        },

        // -- activity polling
        /**
         * @param {function} callback
         * @param {object|function} data
         */
        setPollingListener(callback, data) {
            this.polling.addListener(callback, data)
        },
        /**
         * @param {function} callback
         */
        unsetPollingListener(callback) {
            this.polling.removeListener(callback)
        },
        /** */
        checkActivity() {
            this.polling.poll()
        },
        // -- end of activity polling

        /** */
        showModal(id, args, actions) {
            this.modalInfo.id = id
            this.modalInfo.args = args || {}
            this.modalInfo.actions = actions || []
            this.modalInfo.show = true
            if (args.uri) {
                const uri = args.uri.split(';')
                this.changeURI(this.getRoute(uri[0]).prepare(...uri.slice(1)), true)
            }
        },
        hideModal() {
            this.changeURI(this.currentRoute)
            this.clearModal()
        },
        clearModal() {
            this.modalInfo = {
                show: false,
                type: '',
                args: {},
                actions: []
            }
        },
        closeModal() {
            window.history.back()
        },
        /** */
        addTestLabel(label) {
            if (this.debug) return {'data-selenium': label}
            else return null;
        },
        /** */
        addLoadingPercentage(value) {
            this.loadingPercentage += value
        },
        setLoadingPercentage(value, delayed = false) {
            if (delayed) {
                setTimeout(() => this.loadingPercentage = value, 100)
            } else this.loadingPercentage = value
        },
        /**  */
        setTitle(title) {
            document.title = title + ' / coconutbox v5'
        },
        /**
         * @param {User} [user]
         */
        setUser(user) {
            if (user && !(user instanceof User))
                throw new TypeError('failed to set user, invalid argument type, an User expected')
            this.user = user || null
        },
        /**
         * @param {object[]} settings
         */
        setSettings(settings) {
            this.settings = new SettingCollection(settings)
            this.settings.forEach(s => associateSetting(this.settings, s))
        },
        /**
         * @param {string} key
         * @param {any} value
         */
        setSettingValue(key, value) {
            if (key in this.settings) {
                this.settings[key] = value
            } else {
                const s = {id: key, value: value}
                this.settings.push(s)
                associateSetting(this.settings, s)
            }
        },
        checkRight(key) {
            if (!this.checkSystemModule(key)) return false
            if (this.user && this.user.isAdmin) return true

            for (let right of this.rights) {
                if (right.id === key) return true
            }
            return false
        },
        /**  */
        checkSystemModule(key) {
            switch (key) {
                case 'TEAMWORK_ACCESS':
                    if (!this.hasSystemModule(CONSTANTS.MODULE_ACCESS.SOCIAL) && !this.hasSystemModule(CONSTANTS.MODULE_ACCESS.TASK))
                        return false
                    break
                case 'SOCIAL_ACCESS':
                    if (!this.hasSystemModule(CONSTANTS.MODULE_ACCESS.SOCIAL) || (!this.user.isAdmin && !this.checkRight('TEAMWORK_ACCESS')))
                        return false
                    break
                case 'TASK_ACCESS':
                    if (!this.hasSystemModule(CONSTANTS.MODULE_ACCESS.TASK) || (!this.user.isAdmin && !this.checkRight('TEAMWORK_ACCESS')))
                        return false
                    break
                case 'CRM_ACCESS':
                    if (!this.hasSystemModule(CONSTANTS.MODULE_ACCESS.CRM)) return false
                    break
                case 'FILE_ACCESS':
                    if (!this.hasSystemModule(CONSTANTS.MODULE_ACCESS.FILE)) return false
                    break
                case 'MEDIA_ACCESS':
                    if (!this.hasSystemModule(CONSTANTS.MODULE_ACCESS.MEDIA)) return false
                    break
                case 'NEWSLETTER_ACCESS':
                    if (!this.hasSystemModule(CONSTANTS.MODULE_ACCESS.MAIL)) return false
                    break
                case 'PIM_ACCESS':
                    if (!this.hasSystemModule(CONSTANTS.MODULE_ACCESS.PIM)) return false
                    break
                case 'PUBLISH_ACCESS':
                    if (!this.hasSystemModule(CONSTANTS.MODULE_ACCESS.PUBLISH)) return false
                    break
                case 'ENTITY_ACCESS':
                    if (!this.hasSystemModule(CONSTANTS.MODULE_ACCESS.ENTITY)) return false
                    break
                case 'SOCIALMEDIA_ACCESS':
                    if (!this.hasSystemModule(CONSTANTS.MODULE_ACCESS.SOCIALMEDIA)) return false
                    break
                case 'GDPR_ACCESS':
                    if (!this.settings.GDPR_ENABLED) return false
                    break
                case 'ACCESS_CHANGELOG':
                case 'DASHBOARD_CHANGELOG':
                    if (!this.settings.DATA_CHANGELOG_ENABLED) return false
                    break
                case 'ACCESS_STATISTICS':
                    if (!this.settings.STATISTICS_ENABLED) return false
                    break
                case 'ACCESS_TRIGGER':
                    if (!this.settings.TRIGGER_ENABLED) return false
                    break

                case 'ACCESS_TERMINOLOGY':
                    return !!this.settings.TERMINOLOGY_ENABLED
                case 'ACCESS_ABSENCE':
                    return !!this.settings.ABSENCE_CALENDAR_ENABLED
                case 'TOOLS_ACCESS':
                    return !!(this.settings.TERMINOLOGY_ENABLED || this.settings.ABSENCE_CALENDAR_ENABLED)

            }
            return true
        },
        /**
         * @param {number} moduleId
         * @returns {boolean}
         */
        hasSystemModule(moduleId) {
            const temp = this.settings.ACTIVE_MODULS
            return !!(temp && temp.split(',').some(v => parseInt(v, 10) === moduleId))
        },

        // -- NAVI
        setNaviItems() {
            let topLevelMenu = []
            let sort = 0

            if (this.hasSystemModule(CONSTANTS.MODULE_ACCESS.DASHBOARD) && this.checkRight('DASHBOARD_ACCESS'))
                topLevelMenu.push({name: 'HOME', route: 'home', icon: 'home', sort: sort++, tooltip: this.l10n('tooltip_home')})

            if (this.checkRight('TEAMWORK_ACCESS'))
                topLevelMenu.push({name: 'TEAM', route: 'teamwork', icon: 'file-alt', sort: sort++, tooltip: this.l10n('tooltip_team')})

            if (this.checkRight('SOCIALMEDIA_ACCESS'))
                topLevelMenu.push({name: 'SOCIAL', route: 'socialmedia', icon: 'share-alt', sort: sort++, tooltip: this.l10n('tooltip_social')})

            if (this.checkRight('CRM_ACCESS'))
                topLevelMenu.push({name: 'CRM', route: 'crm', icon: 'users', sort: sort++, tooltip: this.l10n('tooltip_crm')})

            if (this.checkRight('FILE_ACCESS'))
                topLevelMenu.push({name: 'FILE', route: 'file', icon: 'folder-open', sort: sort++, tooltip: this.l10n('tooltip_file')})

            if (this.checkRight('MEDIA_ACCESS'))
                topLevelMenu.push({name: 'MEDIA', route: 'media', icon: 'image', sort: sort++, tooltip: this.l10n('tooltip_media')})

            if (this.checkRight('NEWSLETTER_ACCESS'))
                topLevelMenu.push({name: 'MAIL', route: 'mail', icon: 'envelope', sort: sort++, tooltip: this.l10n('tooltip_mail')})

            if (this.checkRight('PIM_ACCESS'))
                topLevelMenu.push({name: 'PIM', route: 'pim', icon: 'book', sort: sort++, tooltip: ''})

            if (this.checkRight('PUBLISH_ACCESS'))
                topLevelMenu.push({name: 'PUBLISH', route: 'publish', icon: 'print', sort: sort++, tooltip: ''})

            if (this.checkRight('ENTITY_ACCESS'))
                topLevelMenu.push({name: 'CONTENT', route: 'entity', icon: 'cube', sort: sort++, tooltip: this.l10n('tooltip_content')})

            if (this.checkRight('GDPR_ACCESS'))
                topLevelMenu.push({name: 'GDPR', route: 'gdpr', icon: 'shield-check', sort: sort++, tooltip: this.l10n('tooltip_gdpr')})

            if (
                this.checkRight('TOOLS_ACCESS') &&
                (this.checkRight('ACCESS_ABSENCE') || this.checkRight('ACCESS_TERMINOLOGY'))
            ) topLevelMenu.push({name: 'TOOL', route: 'toolsSurvey', icon: 'wrench', sort: sort++, tooltip: ''})


            for (let ix in this.customLinks) {
                if (this.checkRight('CUSTOM_LINK_' + (parseInt(ix) + 1))) {
                    topLevelMenu.push({
                        name: this.customLinks[ix].title[this.systemLocale],
                        route: 'customLink',
                        href: this.customLinks[ix].href,
                        icon: this.customLinks[ix].icon,
                        sort: sort++,
                        tooltip: this.customLinks[ix].tooltip?.[this.systemLocale] || ''
                    })
                }
            }

            topLevelMenu.sort((a, b) => {
                if (a.sort < b.sort) return -1
                if (a.sort > b.sort) return 1
                return 0
            })

            topLevelMenu.forEach(v => v.href = v.href || this.getRoutePath(v.route))
            // href: (v.route === 'customLink') ? v.href : this.getRoutePath(v.route),
            this.topLevelMenu = topLevelMenu
        },
        rebuildTopLevelNavi() {
            const route = this.getRoutePath('config.branding.links')
            ajax.json(route).then(({response}) => {
                this.customLinks = response
                this.setNaviItems()
            })
        },
        // -- END OF NAVI
        logout(state = true) {
            this.polling.stop()
            this.user = null
            window.logout = state
            this.changeURI(this.currentRoute = this.getRoute('login.form'))
        },
        /**
         * @return {string}
         */
        reloadCommonCSS() {
            const link = document.querySelector("link[rel=stylesheet][href*='common.css']")
            return link.href = link.href.replace(/\?.*|$/, "?" + Date.now())
        }
    },
    watch: {
        currentRoute(to, from) {
            console.log('watch.currentRoute', from?.controller + '::' + from?.action, ' to ', to?.controller + '::' + to?.action)
            if (from && this.redirectRouteController(from) === this.redirectRouteController(to)) return
            const vm = this.$refs.viewComponent || {}
            if (vm.$options?.name?.indexOf('Controller') > 0 && vm.$options.name === from.controller) {
                if (!this.user && vm.$options.name === 'AuthController') return
                console.log('TODO: child.$destroy()', 'whyever')//ctrl.$destroy()
            }
        },
        systemLocale(locale, from) {
            if (from === null) return;
            if (locale === null) {
                this.setEnvironment()
                return
            }
            this.splashScreen()
            //this.$options.created.forEach(created => created.call(this)) const link = this.getRoutePath('switchLanguage', locale)
            const route = this.getRoutePath('switchLanguage', locale)
            ajax.json(route).then(({response}) => {
                this.systemLocale = null
            }).catch(e => console.error(e))
        }
    },
    errorCaptured(err, vm, info) {
        console.error('errorCaptured', err, 'vm:', vm, 'info:', info)
        this.errorScreen(err)
        return false
    },/*
    warnHandler(err, vm, info) {
        console.warn('warnHandler', err, 'vm:', vm, 'info:', info);
        return false;
    },*/
    created() {
        let baseUri
        if (!document.baseURI) {
            baseUri = document.getElementsByTagName("base")[0]
            if (baseUri) baseUri = baseUri.href
        } else baseUri = document.baseURI
        baseUri = (baseUri || '/').substr(document.location.origin.length)
        this.routing.setBase(baseUri)

        this.setEnvironment().then(() => {
            window.addEventListener('popstate', ({state}) => {
                if (!state?.modal && this.modalInfo.show) this.clearModal()
                this.currentRoute = this.findRouteByPath(document.location.pathname)
            })
        })

        window.$root = this
    },
    provide() {
        return {
            l10n: this.l10n,
            loadDictionary: this.loadDictionary,
            setLoadingPercentage: this.setLoadingPercentage,
            eventBus: EventBus,
            addTestLabel: this.addTestLabel,

            languages: computed(() => this.languages),
            systemLocale: computed(() => this.systemLocale),
            currentRoute: computed(() => this.currentRoute),
            texts: computed(() => this.texts),
            CONSTANTS: computed(() => this.CONSTANTS)
        }
    }
};
</script>
