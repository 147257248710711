<template>
    <div id="errorPage">
        <div id="errorInfo">
            <div id="errorImage"></div>
            <slot name="default">
                <div v-if="underConstruction">
                    <h1>{{ $root.l10n('under_construction') }}</h1>
                    <p>
                        {{ this.message || $root.l10n('area_under_construction') }}
                    </p>
                </div>
                <div v-else-if="pageNotFound">
                    <p>
                        {{ $root.l10n('sorry') }}<br>
                        {{ $root.l10n('page_not_found') }}<br>
                        {{ $root.l10n('already_busy_looking_for_her') }}
                    </p>
                </div>
                <div v-else>
                    <p>
                        {{ $root.l10n('sorry') }}<br>
                        {{ message }}
                    </p>
                </div>
            </slot>
        </div>
        <div v-if="debugShown" id="debugOutput">
            <h2>Debug output</h2>
            <pre><slot name="debug">{{ debugOutput }}</slot></pre>
        </div>
    </div>
</template>

<script>
import ErrorWrapper, { Construction, NotFound, Response } from '../../lib/models/errorWrapper'

export default {
    name: "ErrorPage",
    props: {
        error: [Object,Error],
        debug: [XMLHttpRequest,Object,String,Error]
    },
    computed: {
        message() {
            return this.error?.message || this.$root.l10n('unknown_error')
        },
        debugOutput () {
            const data = this.$slots.debug || this.debug ||
                (this.error instanceof Response && this.error) ||
                (this.error instanceof ErrorWrapper && this.error.data) ||
                (this.error instanceof Error && this.error.stack) || this.error

            if (data instanceof XMLHttpRequest) {
                return new Response(data)
            } else if (data?.code !== undefined) {
                return `Response code: ${data.code}\n${data.message || this.$root.l10n('unknown_error')}`
                    + (data.response ? "\n\n" + data.response : '')
            } else return data
        },
        debugShown () {
            return this.$root.debug && this.debugOutput
        },
        underConstruction () {
            return this.error instanceof Construction
        },
        pageNotFound () {
            return (this.error instanceof NotFound) ||
                (this.error?.data instanceof XMLHttpRequest)
        }
    },
    errorCaptured(err, vm, info){
        console.error('errorHandler', err, 'vm:', vm, 'info:', info)
        return false
    }
}

export {
    Construction as ConstructionError,
    NotFound as NotFoundError
}
</script>