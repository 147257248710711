<template>
    <div class="info-short d-flex" >
        <div>
            <TextOverflow>{{ title }}</TextOverflow>
            <div class="info-short-subtitle"><TextOverflow>{{ subtitle }}</TextOverflow></div>
        </div>
        <template v-if="img">
            <IconLight v-if="icon" :icon="icon" class="img" />
            <img v-else :src="src" class="img" />
        </template>
    </div>
</template>

<script>
import IconLight from '../../utility/IconLight'
import TextOverflow from "../../utility/TextOverflow";

export default {
    name: 'ModalSidebarInfo',
    components: {TextOverflow, IconLight },
    props: {
        title: String,
        subtitle: String,
        imagePath: Array
    },
    computed: {
        /**
         * @returns {?Array} */
        img () {
            return (this.imagePath instanceof Array && this.imagePath.length > 1) ? this.imagePath : null
        },
        /**
         * @returns {?string}
         */
        icon () {
            return this.img && this.img[0] === 'icon' ? this.imagePath[1] : null
        },
        /**
         * @returns {?string}
         */
        src () {
            return this.img && this.img[0] !== 'icon' ? this.imagePath[1] : null
        }

    }
}
</script>