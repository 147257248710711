<template>
    <div class="popup-sidebar" ref="popupSidebar">
        <ModalSidebarInfo
            :title="title"
            :subtitle="subtitle"
            :imagePath="imagePath"
        />
        <ul class="list-primary-action" ref="primaryActionList">
            <li
                v-for="(item, index) in primaryActions"
                :key="index"
                :class="activeAction === item.id ? 'active' : null"
                @click="$emit('changeActiveContent', item)"
                class="primary-action"
                ref="primaries"
            >
                <div class="primary-action-label-container">
                    <div><TextOverflow>{{ item.name.toUpperCase() }}</TextOverflow></div>
                    <div class="action-info"><TextOverflow>{{ item.title }}</TextOverflow></div>
                </div>
                <div class="primary-action-Icon">
                    <IconLight v-if="item.icon" :icon="item.icon" />
                </div>
            </li>
        </ul>
        <div v-if="secondaryActions.length > 0"
             class="list-secondary-action"
        >
                <div>
                    <IconLight icon="ellipsis-h" />
                    {{ $root.l10n('further_actions').toUpperCase()}}
                </div>
                <ul class="list-group list-group-root list-navi-right well" id="navi-editActions" ref="secondaryActionList">
                    <li
                        v-for="(item, index) in secondaryActions"
                        :key="index"
                        :class="activeAction === item.id ? 'active' : null"
                        @click="$emit('changeActiveContent', item)"
                        class="list-group-item"
                        ref="secondaries"
                    >
                        <TextOverflow>{{ item.name }}</TextOverflow>
                    </li>
                </ul>
        </div>
        <Dropdown v-if="hiddenActions.length"
            class="popup-action-dropdown"
            :align="ddAlign.leftBottom"
        >
            <template #toggler="{toggle}">
                <button
                    @click="toggle"
                    class="btn-std btn-icon-only dropdown-menu-btn"
                >
                    <IconLight icon="ellipsis-h" />
                </button>
            </template>
            <template #default="{toggle}">
                <ul class="action-dropdown-list" @click="toggle">
                    <li
                        v-for="(item, index) in hiddenActions"
                        :key="index"
                        :class="activeAction === item.id ? 'active' : null"
                        @click="$emit('changeActiveContent', item)"
                        class="list-group-item"
                        ref="secondaries"
                    >
                        <button class="text-overflow" type="button">{{ item.name }}</button>
                    </li>
                </ul>
            </template>
        </Dropdown>
    </div>
</template>

<script>
import ModalSidebarInfo from './ModalSidebarInfo.vue'
import IconLight from '../../utility/IconLight.vue'
import TextOverflow from "../../utility/TextOverflow";
import Dropdown, {ddAlign} from '../../utility/Dropdown'

export default {
    name: 'ModalSidebar',
    components: {
        Dropdown,
        TextOverflow,
        ModalSidebarInfo,
        IconLight
    },
    props: {
        actions: Array,
        activeAction: [Number,String],
        title: String,
        subtitle: String,
        imagePath: Array
    },
    setup() {
        return {
            ddAlign
        }
    },
    data() {
        return {
            visibleActionCn: this.actions.length,
            sidebarHeight: 0,
            actionPositions: [],
        }
    },
    computed: {
        primaryActions () {
            const actions = []
            const total = Math.min(this.actions.length, this.visibleActionCn)
            for (let i = 0; i < total; i++) {
                if (!this.actions[i] || !this.actions[i].primary) continue;
                actions.push(this.castAction(this.actions[i]))
            }
            return actions
        },
        secondaryActions () {
            const actions = []
            const total = Math.min(this.actions.length, this.visibleActionCn)
            for (let i = 0; i < total; i++) {
                if (!this.actions[i] || this.actions[i].primary) continue;
                actions.push(this.castAction(this.actions[i]))
            }
            return actions
        },
        hiddenActions() {
            const actions = []
            for (let i = this.visibleActionCn; i < this.actions.length; i++) {
                if (!this.actions[i]) continue
                actions.push(this.castAction(this.actions[i]))
            }
            return actions
        }
    },
    methods: {
        castAction(action) {
            return Object.assign({
                id: null,
                name: null,
                subtitle: null,
                description: null,
                icon: null,
                data: {}
            }, action)
        },
        getActionPositions (){
            this.actionPositions = []
            for (let action of (this.$refs.primaries || [])) {
                this.actionPositions.push(action.scrollTop + action.offsetTop)
            }

            for (let action of (this.$refs.secondaries || [])) {
                this.actionPositions.push(action.scrollTop + action.offsetTop)
            }
        },
        rearrangeActions() {
            if (this.$refs.popupSidebar != null) {
                this.sidebarHeight = this.$refs.popupSidebar.clientHeight - 140

                let index = 0, visible = 0
                for (let action of (this.$refs.primaries || [])) {
                    if(this.actionPositions[index] > this.sidebarHeight && window.innerWidth > 1200){
                        visible = index
                        break
                    }
                    index++
                }

                for (let action of (this.$refs.secondaries || [])) {
                    if(this.actionPositions[index] > this.sidebarHeight || window.innerWidth < 1201){
                        visible = index
                        break
                    }
                    index++
                }
                this.visibleActionCn = visible || this.actions.length
            }
        },
        resizeHandler() {
            this.visibleActionCn = this.actions.length
            this.$nextTick(() => this.rearrangeActions())
        }
    },
    mounted(){
        this.getActionPositions()
        this.rearrangeActions()
        window.addEventListener('resize', this.resizeHandler)
    },
    destroy() {
        window.removeEventListener('resize', this.resizeHandler)
    }
}
</script>