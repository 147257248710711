<template>
    <ElementGroup v-if="value || $slots.default" info>
        <div class="input-wrapper text-output" :class="toggle ? 'toggle' : null" v-resize-observer="resizeHandler" ref="inputWrapper">
            <label>{{ name }}</label>
            <div v-if="headlineOutput" class="text-output-headline">{{ headlineOutput }}</div>
            <div class="input-group" v-if="(outputValue instanceof Array)">
                <template v-if="options">
                    <span
                        v-for="(output,ix) in outputValue"
                        :class="tags || output.color ? 'text-output-tags' : null"
                        :style="{ backgroundColor: output.color }"
                        :key="ix"
                    >
                        <template v-if="!tags && ix !== 0">
                            , {{output.title}}
                        </template>
                        <template v-else>
                            {{output.title}}
                        </template>
                    </span>
                </template>
                <div v-else v-for="(output,ix) in outputValue" :key="ix">
                    <span v-if="html" v-html="output"></span>
                    <span v-else>{{output}}</span>
                </div>
            </div>
            <div class="input-group" v-else>
                <span v-if="html" v-html="outputValue"></span>
                <span v-else-if="value">{{outputValue}}</span>
                <span v-else><slot/></span>
            </div>
        </div>
        <button v-if="overflow && !toggle" class="text-link link-blue show-all" @click="toggle = true">{{ $root.l10n('show_it_all') }}</button>
    </ElementGroup>
</template>

<script>
import ElementGroup from '../ElementGroup'
import resizeObserver from "@/lib/mixins/resizeObserver"

export default {
    name: "TextOutput",
    components: { ElementGroup },
    props: {
        name: String,
        options: Array,
        value: [Array, String, Number],
        html: Boolean,
        tags: Boolean
    },
    directives: {
      resizeObserver
    },
    data() {
        return {
            overflow: false,
            toggle: false
        }
    },
    computed: {
        parsedValue() {
            return this.parseValue(this.value || [])
        },
        headlineOutput() {
            return this.parsedValue[0]
        },
        outputValue () {
            const value = this.parsedValue[1]

            const non_value = '-';
            if (this.options) {
                const outputValues = []
                const rawValueArr = typeof value !== 'string' ?
                    value instanceof Array ? value : [value] : value.split(',')
                for (let i = 0; i < this.options.length; i++) {
                    if (this.options[i].value && rawValueArr.indexOf(this.options[i].value) !== -1)
                    outputValues.push({title: this.options[i].title, color: this.options[i].color})
                }
                console.log('outputValues ' , outputValues)
                return outputValues.length > 0 ? outputValues : non_value
            }

            console.log('value ' , value)

            return value || non_value
        }
    },
    methods: {
        parseValue(value) {
            const pos = value && typeof value === 'string' ? value.indexOf("\n\n\n") : -1
            return pos > -1 ? [value.substring(0, pos), value.substring(pos + 3)] : ['', value]
        },
        resizeHandler() {
            this.overflow = (this.$refs.inputWrapper.scrollHeight - 1) > Math.ceil(this.$refs.inputWrapper.clientHeight)
        }
    }
}
</script>