<template>
    <div class="tab" :class="active ? 'active' : null">
        <div class="tab-title" v-if="!!label">{{ label }}</div>
        <slot />
    </div>
</template>

<script>
export default {
    name: "TabPanel",
    props: {
        active: Boolean,
        label: String
    },
    methods: {
        focusFirstInput () {
            const selectors = ['select', 'input', 'textarea'].join(',')
            const el = this.$el.querySelector(selectors)
            if (
                !el?.disabled &&
                !el?.classList.contains('mx-input') &&
                !el?.classList.contains('multiselect__input')
            ) setTimeout(() => el?.focus(), 1)
        }
    },
    watch: {
        active(active){
            if (active) this.focusFirstInput()
        }
    },
    mounted() {
        if (this.active) this.focusFirstInput()
    }
}
</script>