<template>
    <div id="user-menu" class="dropdown-menu-list dropdown-menu-top-right">
        <div class="triangle"></div>
        <div class="dropdown-menu-list-content" v-if="!submenu">
            <div class="dropdown-menu-list-header">
                <h3>
                    <IconSolid icon="user" />
                    {{ $root.l10n('user_menu') }}
                    <div class="btn-std btn-small btn-icon-only" @click.stop="$emit('clickMenuItem')"><IconLight icon="times" /></div>
                </h3>
            </div>
            <ul class="list" @click="$emit('clickMenuItem')">
                <li :class="isActive(profileRoute) ? 'active' : null">
                    <a :href="profileRoute" @click.prevent="openLink" v-bind="$root.addTestLabel('profile_settings')">{{ $root.l10n('profile_survey') }}</a>
                </li>
                <li v-if="$root.user.isAdmin">
                    <a :href="clearCacheRoute" @click.prevent="openInform" v-bind="$root.addTestLabel('clear_full_cache')">{{ $root.l10n('clear_full_cache') }}</a>
                </li>
                <li v-if="$root.user.isAdmin">
                    <a :href="systemUpdateRoute" target="_blank" v-bind="$root.addTestLabel('system_update')">{{ $root.l10n('system_update') }}</a>
                </li>
                <li v-if="$root.checkRight('SYSTEMSETTINGS_ACCESS')" :class="isActive(systemSettingsRoute) ? 'active' : null">
                    <a :href="systemSettingsRoute" @click.prevent="openLink" v-bind="$root.addTestLabel('system_settings')">{{$root.l10n('system_settings') }}</a>
                </li>
                <li v-if="$root.languages.length > 1">
                    <button @click.stop="submenu = 'locale'" v-bind="$root.addTestLabel('system_language')"><span>{{$root.l10n('system_language') }}</span> <IconThin icon="chevron-right" class="icon-right"/></button>
                </li>
                <li class="separated-item"><button @click="logout" v-bind="$root.addTestLabel('logout-btn')">{{ $root.l10n('logout') }}</button></li>
            </ul>
        </div>

        <div v-else-if="submenu === 'locale'" class="dropdown-menu-list-content dropdown-menu-sub" >
            <div class="dropdown-menu-list-header">
                <h3 @click.stop="submenu=null">
                    <IconLight icon="chevron-left" />
                    {{ $root.l10n('system_language') }}
                    <div class="btn-std btn-small btn-icon-only" @click.stop="$emit('clickMenuItem')"><IconLight icon="times" /></div>
                </h3>
            </div>
            <ul class="list" @click="$emit('clickMenuItem')">
                <li v-for="lg in $root.languages" :key="lg.locale" :class="lg.locale === $root.systemLocale ? 'active' : null">
                    <button @click="changeLocale(lg.locale)">{{lg.localName}}</button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

const ajax = require('../../../lib/ajax.js')
import { isControlKeyPressed } from '../../../lib/utility'

import IconLight from '../../utility/IconLight.vue'
import IconSolid from "../../utility/IconSolid"
import IconThin from "@/components/utility/IconThin"

export default {
    name: 'UserMenu',
    components: {IconThin, IconSolid, IconLight},
    data() {
        return {
            submenu: null,
            profileRoute: this.$root.getRoutePath('profile.survey'),
            clearCacheRoute: this.$root.getRoutePath('system.clearCache'),
            systemUpdateRoute: this.$root.getRoutePath('system.update'),
            systemSettingsRoute: this.$root.getRoutePath('setting.main')
        }
    },
    methods: {
        isActive(url) {
            return document.location.pathname.indexOf(url) === 0
        },
        logout() {
            ajax.json(this.$root.getRoute('logout').path).then(({code}) => {
                if (!code) this.$root.logout()
            }).catch(e => console.error(e))//todo output into popup message
        },
        openInform(event) {
            if (isControlKeyPressed(event)) return
            const link = event.target.getAttribute('href')
            if (link === '/#') return ;//wtf?!
            //todo output (OK & Error) into popup message
            //todo: show
            ajax.json(link).then(({response}) => {
                window.alert(response.join('\n'))
            }).catch(e => console.error(e))
        },
        openLink(event) {
            if (isControlKeyPressed(event)) return
            this.$root.goToRoute(event.target.getAttribute('href'))
        },
        changeLocale(locale) {
            this.$root.systemLocale = locale
        },
        async loadDictionary() {
            this.ready = await this.$root.loadDictionary('users')
        },
    }
};
</script>