<template>
    <div class="popup-header">
        <div v-if="backTitle !== null" class="popup-back-button" @click="$emit('back')">
            <IconLight icon="chevron-left" />
            <span>{{ backTitle !== true ? backTitle : $root.l10n('back') }}</span>
        </div>
        <div class="popup-title"><h1><TextOverflow>{{ headline }}</TextOverflow></h1></div>
        <Dropdown
            v-if="languageSwitch"
            :headline="$root.l10n('attribute_language').toLocaleUpperCase()"
            :icon="'globe'"
        >
            <template #toggler="{toggle}">
                <button
                    type="button"
                    class="btn-std btn-icon-only btn-big btn-view dropdown-menu-btn"
                    @click="toggle"
                    v-tooltip="$root.l10n('attribute_language')"
                >
                    <IconLight icon="globe" />
                </button>
            </template>
            <template #headline>
                        <h3>
                            <IconSolid icon="globe" />
                            {{ $root.l10n('attribute_language').toUpperCase()}}
                        </h3>
            </template>
            <template #list="{toggle}">
                        <li
                            v-for="language in languageSet"
                            :key="language.locale"
                            :class="language.locale === this.locale ? 'active' : null"
                            @click="() => { toggle(); switchLanguage(language.locale); }"
                        >
                            <a>{{ language.title }}</a>
                        </li>
            </template>
        </Dropdown>
        <div
            v-if="tabSwitch"
            @click="$emit('toggleLayout')"
            class="btn-std btn-icon-only btn-big tab-mode-button"
            v-tooltip="$root.l10n('change_view')"
        >
            <IconCustom icon="change-view"/>
        </div>
        <div class="btn-std btn-option btn-big btn-icon-only popupClose" @click="$emit('close')" v-tooltip="$root.l10n('close')">
            <IconLight icon="times" />
        </div>
    </div>
</template>

<script>
import IconLight from '../../utility/IconLight.vue'
import IconSolid from '../../utility/IconSolid.vue'
import Dropdown from '../../utility/Dropdown.vue'
import TextOverflow from "../../utility/TextOverflow";
import IconCustom from "@/components/utility/IconCustom";

export default {
    name: 'ModalContentHeader',
    components: {
        Dropdown,
        IconCustom,
        TextOverflow,
        IconLight,
        IconSolid,
    },
    props: {
        headline: String,
        locale: String,
        languageSet: Array,
        backTitle: [String, Boolean],
        tabSwitch: Boolean
    },
    data() {
        this.switchLanguage(this.locale)

        return {

        }
    },
    computed: {
        languageSwitch () {
            return this.languageSet && this.languageSet.length > 1
        }
    },
    methods: {
        switchLanguage(locale) {
            const language = locale ? this.languageSet?.find(lg => lg.locale === locale) : this.languageSet?.[0]
            if (!language) return ;

            if (language.locale !== this.locale)
                this.$emit('update:locale', language.locale)
        }
    },
    watch: {
        languageSet () {
            this.switchLanguage(this.locale)
        }
    }
}
</script>