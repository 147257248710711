<template>
    <div id="headerWrapper" class="d-flex flex-row">
        <div id="logo" class="logo-logged"></div>
        <SearchBar/>
        <Toolbar />
    </div>
</template>

<script>
import SearchBar from "./search/SearchBar.vue";
import Toolbar from "./Toolbar.vue";

export default {
    name: "Header",
    components: { SearchBar, Toolbar },
}
</script>
