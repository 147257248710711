<template>
    <div id="splash-screen">
        <ErrorPage v-if="failed" :error="failed"/>
        <LoadingSpinner :progress="progress" :animation-speed="0" class="spinner-center-vertical spinner-big" />
    </div>
</template>

<script>
import LoadingSpinner from './LoadingSpinner.vue'
import ErrorPage from "./ErrorPage";

export default {
    name: "SplashScreen",
    components: {
        ErrorPage,
        LoadingSpinner
    },
    props : {
        action: String,
        args: Object
    },
    computed: {
        message() {
            return this.args.message || 'Please wait...';
        },
        progress() {
            return typeof this.args.progress === 'undefined' ? null : Math.min(100, this.args.progress);
        },
        failed() {
            return typeof this.args.failed === 'undefined' ? null : this.args.failed;
        }
    },
    created() {},
    mounted() {
        this.$root.setTitle('Loading');
    }
}
</script>