<template>
    <form
        v-show="enabled"
        id="headerSearch"
        :class="{onfocus: focused, onchange: enabled && changed, onsearch: enabled && active, disabled: !enabled}"
        @submit.prevent="search"
        v-click-outside="() => {focused = false}"
    >
        <button type="button" id="search-filter" :class="{'search-terms-active': searchTerms.length > 1, 'open' : searchPanelShown}" @click.stop="toggleSearchPanel">
            <IconSolid icon="filter"/>
            <span>{{$root.l10n('filter')}}</span>
            <div class="triangle"></div>
        </button>
        <Dropdown
            v-if="searchTerms.length > 1"
            id="searchTermDropdownWrapper"
            :align="ddAlign.topLeft"
            :size="ddSize.large"
            :scrollable="true"
        >
            <template #toggler="{toggle, toggler}">
                <button
                    id="searchTermDropdownButton"
                    type="button"
                    :class="toggler ? 'toggle' : null"
                    @click.stop="toggle"
                    v-tooltip="(searchTerms.length - 1) + ' ' +  $root.l10n('search_terms_active')"
                >
                    <span>{{ searchTerms.length - 1 }} </span><IconLight icon="chevron-up"/>
                </button>
            </template>
            <template #headline>
                {{$root.l10n('following_search_terms_are_selected')}}
            </template>
            <template #default="{toggle}">
                        <AlertBox
                            v-for="({id,type,icon,title,text}) in searchTerms"
                            :key="id"
                            :type="type"
                            :icon="icon"
                            :title="title"
                            :text="text"
                        >
                            <template #button>
                                <div class="alert-box-button">
                                    <button v-if="icon === 'cog'" type="button" class="btn-std btn-icon-only" @click="() => { toggle(); openSearchOptionPanel(); }">
                                        <IconLight icon="edit"/>
                                    </button>
                                    <button v-else type="button" class="btn-std btn-icon-only" @click="() => { toggle(); deleteSearchTerm(id); }">
                                        <IconLight icon="trash"/>
                                    </button>
                                </div>
                            </template>
                        </AlertBox>
            </template>
        </Dropdown>
        <div id="searchInputContainer">
            <div id="searchInputWrapper" @click="setFocusToInput" ref="searchInputWrapper">
                <div class="search-input-content"
                     :class="{show: showInputOverflow, overflow: searchIsOverflow}"
                     ref="searchInputContent"
                     @mouseenter="checkSearchOverflow"
                     v-click-outside="() => {showInputOverflow = false; this.searchIsOverflow = false}"
                >
                    <SearchWord v-for="searchWord in searchWords"
                        :key="searchWord.id"
                        @remove="removeSearchWord(searchWord.id)"
                        @update:modelValue="afterChange()"
                        v-model="searchWord.title"
                    />
                    <input
                        @focus.prevent="focus"
                        @input="change"
                        @blur="onBlur"
                        @keydown.enter.stop="onBlur"
                        @keydown.delete="onDelete"
                        @paste.prevent="onPaste"
                        id="searchInput"
                        ref="searchInput"
                        name="qt"
                        type="text"
                        v-model="inputText"
                        :disabled="!enabled"
                        :placeholder="!active ? $root.l10n('search_placeholder') : ''"
                    >
                </div>
            </div>
            <button type="button" @click="clearSearch" class="search-delete" :disabled="!enabled" v-tooltip="$root.l10n('search_end')">
                <IconLight icon="xmark" />
            </button>
            <button type="submit" @click.prevent="search" class="search-btn search-start" :disabled="!enabled">
                <IconLight icon="search" />
                <span>{{$root.l10n('search_start')}}</span>
            </button>
        </div>
        <div v-if="searchSaveShown" class="search-presets-btn" @click="saveSearchPreset">
            <IconLight icon="star" />
        </div>
        <div v-if="searchPanelRequested"
            id="searchPanelLayer"
            :class="{open: searchPanelShown}"
        >
            <component :is="searchPanelComponent"
                v-bind="searchPanelArgs"
                v-model:filter="filter"
                v-model:settings="settings"
                :terms="searchPanelTerms"
                @update:filter="onFilterChanged"
                @update:settings="settingChanged"
                @terms="termsChanged"
                v-click-outside="hideSearchPanel"
                ref="searchPanel"
            />
        </div>
    </form>
</template>

<script>
import {markRaw} from 'vue'
import IconLight from '../../../utility/IconLight'
import IconSolid from '../../../utility/IconSolid'
import SearchPanel from "./SearchPanel"
import ClickOutside from 'click-outside-vue3'
import EventBus from '../../../../lib/helpers/EventBus'
import DateInput from "@/components/forms/inputs/DateInput";
import AlertBox from "@/components/utility/AlertBox";
import ButtonStd from "@/components/forms/ButtonStd";
import searchText, {junction, sequence} from '../../../../lib/mixins/searchText'
import Dropdown, {ddSize, ddAlign} from '../../../utility/Dropdown'
import { objectAssignDeep } from '../../../../lib/utility'
import SearchWord from "@/components/layout/header/search/SearchWord";

export default {
    name: "SearchBar",
    components: {
        SearchWord,
        ButtonStd,
        AlertBox,
        DateInput,
        Dropdown,
        SearchPanel,
        IconLight,
        IconSolid
    },
    mixins: [searchText],
    setup() {
        return {
            ddAlign,
            ddSize
        }
    },
    data() {
        const closeOnMode = this.$root.settings?.find(s => s.id === "DIALOG_CLOSE_ON").value || '2'

        return {
            enabled: false,
            focused: false,
            changed: false,
            searchSaveEnabled: false,
            searchPanelShown: false,
            searchPanelRequested: false,
            searchPanelComponent: markRaw(SearchPanel),
            searchPanelArgs: {},
            searchPlaceholder: this.$root.l10n('search_placeholder'),
            searchWords: [],
            active: false,
            searchIsOverflow: false,
            showInputOverflow: false,
            allowCloseOnEsc: closeOnMode === '2' || closeOnMode === '4',
            allowCloseOnOutsideClick: closeOnMode === '3' || closeOnMode === '4',
            filter: {
                //qt: ''
            },
            searchPanelTerms: {},
            searchTerms: [],
        }
    },
    computed: {
        selected () {
            return this.filter?.selected || 0
        },
        spTabs () {
            return {
                opt: this.$root.l10n('search_options')
            }
        },
        spTabsCount () {
            return Object.keys(this.spTabs).length
        },
        /**
         * @returns {Boolean}
         * @override searchText
         */
        emptyValue() {
            return this.searchWords.length === 0
        },
        /**
         * @returns {String}
         * @override searchInput
         */
        searchValue () {
            const wordCn = this.searchWords.length
            const p = wordCn > 1 ? '"%s"' : '%s'
            return wordCn > 0 ? this.getSearchValue(
                this.searchWords.map(w => w.title.indexOf(' ') > -1 ? p.replace('%s', w.title) : w.title).join(' '),
                this.getSettings()
            ) : ''
        },
        /**
         * @returns {String}
         */
        searchValueTerm () {
            return this.searchWords.length > 0 ?
                this.getSearchValue(this.searchWords.map(w => w.title).join('|'), this.getSettings()) : ''
        },
        /**
         * @returns {Boolean}
         */
        searchSaveShown () {
            return this.searchSaveEnabled && this.enabled && this.active
        }
    },
    directives: {
        clickOutside: ClickOutside.directive
    },
    methods: {
        onBlur() {
            this.addSearchWord(this.inputText)
            this.afterChange()
        },
        onPaste(event) {
            this.addSearchWord(event.clipboardData.getData('text'), true)
            this.afterChange()
        },
        onDelete(e) {
            if (
                e.keyCode === 8 && 
                this.inputText.length === 0 &&
                this.searchWords.length > 0
            ) {
                this.searchWords.pop()
            }
        },
        change() {
            this.searchInputSizeHandler()
            if (this.settings.seq !== sequence.exact && this.inputText.indexOf(' ') > -1) {
                this.addSearchWord(this.inputText)
                this.afterChange()
            }

            this.checkSearchOverflow()
        },
        /** */
        afterChange() {
            this.inputText = ''
            this.filter.qt = this.searchValue
            this.setSearchPanelQTerms()
            this.changed = !this.isSearchEmpty()
        },
        /**
         * @param {string} text
         * @param {boolean} p
         */
        addSearchWord(text, p) {
            // reduce multiple white spaces in row to one
            text = text.trim().replace(/\s+/g, ' ')
            if (text.length === 0) return
            const parts = !p ? this.settings.seq === sequence.exact ? [text] : text.split(' ') :
                text.replace(/".*?"/g, match => encodeURIComponent(match)).split(' ')
                    .map(s => s.replace(/%22(.+)%22/g, (_,m) => decodeURIComponent(m)))

            for (let i = 0; i < parts.length; i++) {
                if (parts[i].replace(/[\p{P}\p{S}\s]/gu, '').trim() === '') continue
                this.searchWords.push({
                    id: 'qt_' + Date.now() + i,
                    title: parts[i],
                    visible: true
                })
            }
        },
        /**
         * @param {number} searchWordId
         */
        removeSearchWord(searchWordId) {
            event.stopPropagation()
            this.__removeSearchWord(searchWordId)
            this.afterChange()
        },
        /**
         * @param {number} searchWordId
         */
        __removeSearchWord(searchWordId) {
            for (let i = 0; i < this.searchWords.length; i++) {
                if (this.searchWords[i].id === searchWordId) {
                    this.searchWords.splice(i, 1);
                }
            }
        },
        /** added QT to searchPanelTerms-Array, if not empty*/
        setSearchPanelQTerms () {
            if (this.searchValueTerm) {
                this.searchPanelTerms.qt = {
                    title: this.$root.l10n('search_free_text'), terms: this.searchValueTerm
                }
            } else if (this.searchPanelTerms.qt) {
                delete this.searchPanelTerms.qt
            }
        },
        focus () {
            if (this.active) return
            this.focused = true
        },
        setEnabled() {
            this.enabled = true
        },
        setDisabled() {
            this.searchSaveEnabled = false
            this.enabled = false
            this.filter = {}
        },
        setSearchSavable() {
            this.searchSaveEnabled = true
        },
        settingChanged(settings) {
            //this.settings = settings
            const parsed = this.getSettings()
            if (parsed.j) this.filter.or = 1
            else if (this.filter.or) delete this.filter.or
            const inputText = this.searchWords.map(s => s.title).join(' ')
            this.filter.qt = this.getSearchValue(inputText, parsed)
        },
        /**
         * @param {string} text
         * @param {object} settings see SearchText.getSettings()
         * @returns {string}
         */
        getSearchValue(text, settings) {
            if (!text && settings.j) settings.j = ''
            return searchText.methods.getSearchValue.call(this, text, settings)
        },
        /**
         * @param {object} filter
         * @param {boolean} merge = false
         * @param {boolean} silent = false
         */
        setFilter(filter, merge = false, silent = false) {
            console.log("setFilter ", filter, merge ? 'merge' : 'replace', silent ? 'silent' : 'trigger')
            if (!this.enabled) return
            const empty = this.isSearchEmpty()

            if (!merge) {
                this.filter = filter ? JSON.parse(JSON.stringify(filter)) : {}
                this.searchPanelTerms = {}
            } else {
                this.filter = objectAssignDeep(this.filter || {}, filter)
            }

            const {settings, value} = this.parseSearchValue(this.filter?.qt || '')
            this.settings = Object.assign({}, this.settings, settings || {})
            if (this.filter.or && this.settings.j !== junction.or) {
                this.settings.j = junction.or
            }
            this.inputText = ''
            this.searchWords = []
            this.addSearchWord(value, true)
            if (!silent) {
                if (!this.isSearchEmpty()) this.search()
                else if (!empty) this.clearSearch()
            }
        },

        /**
         * trigger search
         */
        search() {
            this.focused = false
            this.changed = false
            if (!this.isSearchEmpty()) {
                this.active = true
                this.searchPanelShown = false
                this.searchTerms = this.buildSearchTerms()
                this.filter = Object.assign({}, this.filter)
                if (this.filter.qt !== undefined && !this.filter.qt) delete this.filter.qt

                this.$store.state.searchIsActive = true
                EventBus.$emit('search.change', this.filter, this.searchPanelTerms)
                EventBus.$emit('selection.show', !!this.selected)
            }
        },
        /**
         * @returns {boolean}
         */
        isSearchEmpty() {
            return this.checkEmptyRecursive(this.filter)
            /*(
                (!this.filter.selected) &&
                (typeof this.filter.qt === 'undefined' || this.filter.qt.length === 0) &&
                (this.checkEmptyRecursive(this.filter))
            )*/
        },
        /** */
        toggleSearch() {
            if (!this.isSearchEmpty()) this.search()
            else this.clearSearch()
        },
        /**
         * @param {any} filter
         * @returns {boolean}
         */
        checkEmptyRecursive(filter) {
            if (filter && typeof filter === 'object') {
                return !(filter instanceof Array ? filter : Object.values(filter))
                    .some(v => !this.checkEmptyRecursive(v))
            } else return null === filter || undefined === filter || String(filter).length === 0
        },
        /** */
        clearSearch() {
            this.focused = false
            this.changed = false
            this.active = false
            this.searchPanelShown = false
            this.inputText = ''
            this.searchWords = []
            this.searchTerms = []
            this.$store.state.searchIsActive = false
            EventBus.$emit('search.change', this.filter = {}, this.searchPanelTerms = {})
            EventBus.$emit('selection.show', !!this.selected)
        },
        /** */
        enterSearch(e) {
            if (e.keyCode === 13) { this.search() }
        },
        /**
         TO DO - Filter von der aktuellen Suche entfernen und Suche aktualisieren
         */
        deleteSearchTerm(id) {
            const chain = id.indexOf('.') > -1 ? id.split('.') : [id]
            let filter_leaf = this.filter
            for (let i = 0; i < chain.length -1; i++) {
                if (chain[i] in filter_leaf) {
                    filter_leaf = filter_leaf[chain[i]]
                } else {
                    filter_leaf = {}
                    break
                }
            }
            if (id in this.searchPanelTerms) {
                delete this.searchPanelTerms[id]
            }
            if (chain[chain.length-1] in filter_leaf) {
                const key = chain[chain.length-1]
                delete filter_leaf[key]
                this.toggleSearch()
            }

        },
        /**
         * @param {Promise.<Vue>} vc
         * @param {object} args
         */
        setSearchPanel(vc, args) {
            this.searchPanelComponent = markRaw(vc || SearchPanel)
            this.searchPanelArgs = args || {}
            this.searchPanelRequested = false
        },
        toggleSearchPanel () {
            this.searchPanelShown = !this.searchPanelShown
            this.searchPanelRequested = true
        },
        openSearchOptionPanel () {
            this.searchPanelShown = true
            this.$refs.searchPanel.tab = 'opt'
        },
        onFilterChanged (...args) {
            this.changed = !this.isSearchEmpty()
            //todo: immediately search
        },
        /** @param {{title: string, terms: string}[]} terms */
        termsChanged(terms) {
            this.searchPanelTerms = Object.assign({},
                this.searchPanelTerms.qt ? {qt: this.searchPanelTerms.qt} : {},
                this.searchPanelTerms.category ? {category: this.searchPanelTerms.category} : {},
                terms
            )

            this.searchTerms = this.buildSearchTerms()
        },
        /** @returns {Object[]} */
        buildSearchTerms() {
            const terms = []
            terms.push({
                id: '__info__',
                type: 'info',
                icon: 'cog',
                title: '',
                text: this.getSettingsAsTerms()
            })

            for (let k in this.searchPanelTerms) {
                if (!this.getFilterByKey(k)) continue
                terms.push(Object.assign(
                    {id: k},
                    this.parseSearchTerm(this.searchPanelTerms[k].terms, this.searchPanelTerms[k].title)
                ))
            }
            return terms.filter(v => !!v)
        },
        setFocusToInput(event) {
            if(event.target.classList.contains('search-word-item') || event.target.parentElement.classList.contains('search-word-item')) return
            this.$refs.searchInput.focus()
        },
        checkSearchOverflow() {
            const minHeight = parseInt(window.getComputedStyle(this.$refs.searchInputContent, null).getPropertyValue("min-height"), 10)
            this.showInputOverflow = true
            this.$nextTick(() => {
                if (this.$refs.searchInputContent.scrollHeight > minHeight) {
                    this.searchIsOverflow = true
                }
            })
        },
        searchInputSizeHandler() {
            const maxWidth = this.$refs.searchInputContent.clientWidth
            const input = this.$refs.searchInput
            input.style.width = this.inputText.length + "ch"
            if (input.clientWidth >= maxWidth) this.onBlur()
        },
        saveSearchPreset($event) {
            if (!this.searchSaveShown) return ;
            EventBus.$emit('searchPreset.saved', $event, this.filter)
        },
        /**
         * @param {string} key
         * @returns {any}
         */
        getFilterByKey (key) {
            if (key in this.filter) return this.filter[key]
            key = key.split('.')
            let f = this.filter
            for (let k of key) {
                if (k && k in f) f = f[k]
                else return null
            }
            return f
        },
        closeOnEsc(e) {
            if (this.allowCloseOnEsc && e.keyCode === 27) {
                this.searchPanelShown = false
            }
        },
        hideSearchPanel(event) {
            let parent = event.target.parentNode
            while (parent.tagName !== 'BODY' && parent !== this.$el) {
                if (!(parent = parent.parentNode)) return ;
            }
            if (event.target.closest('#' + this.$refs.searchInputWrapper?.id) !== null) return
            this.searchPanelShown = false
        }
    },
    created() {
        setTimeout(_ => {
            document.addEventListener('keydown', this.closeOnEsc)
        }, 0)
    },
    mounted() {
        EventBus.$on('search.set', this.setFilter)
        EventBus.$on('search.terms', this.termsChanged)
        EventBus.$on('search.enable', this.setEnabled)
        EventBus.$on('search.disable', this.setDisabled)
        EventBus.$on('search.savable', this.setSearchSavable)
        EventBus.$on('search.panel', this.setSearchPanel)
        document.addEventListener('keydown', this.enterSearch)
    },
    unmounted() {
        EventBus.$off('search.set', this.setFilter)
        EventBus.$off('search.enable', this.setEnabled)
        EventBus.$off('search.disable', this.setDisabled)
        EventBus.$off('search.savable', this.setSearchSavable)
        EventBus.$off('search.panel', this.setSearchPanel)
        EventBus.$off('search.terms', this.termsChanged)
        document.removeEventListener('keydown', this.enterSearch)
        document.removeEventListener('keydown', this.closeOnEsc)
    }
}
</script>