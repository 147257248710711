
export default {
    props: {
        modelValue: [Date,String,Number,Object,Array],
        parentForm: {
            type: String,
            default: 'FormBox'
        },
        defaultValueRef: {
           type: String,
           default: function(){
               return Math.random().toString(36).substr(2, 9)
           }
        },
        defaultResettable: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            resettable: true,
            defaultValue: undefined
        }
    },
    computed: {
        /**
         * @returns {any}
         * @see modelValue
         */
        computedValue () {
            return this.modelValue
        },
        /**
         * returns true if value was changed, in case defaultValue was set
         * @returns {boolean}
         */
        isDirty () {
            const value = this.computedValue
            if (!this.defaultResettable || !this.resettable) {
                return false
            } else if (
                this.defaultValue === null || //value === null ||
                this.defaultValue === undefined //|| value === undefined
            ) {
                return value ? typeof value === 'object' ? value instanceof Array ?
                    value.length > 0 : Object.keys(value).length > 0 : !!value : false

            } else if (this.defaultValue instanceof Array) {
                if (!value || this.defaultValue.length !== value.length) return true
                for (let i = 0; i < this.defaultValue.length; i++) {
                    if (!value?.includes(this.defaultValue[i])) return true
                    if (!this.defaultValue.includes(value[i])) return true
                }
                return false
            } else if (this.defaultValue instanceof Date || value instanceof Date) {
                //console.log(String(this.defaultValue) !== String(value), String(this.defaultValue) , String(value))
                return String(this.defaultValue) !== String(value)
            } else if (typeof this.defaultValue === 'object') {
                let dvk = Object.keys(this.defaultValue), vk = value ? Object.keys(value) : []
                if (dvk.length !== vk.length) return true
                for (let k = 0; k < dvk.length; k++) {
                    if (this.defaultValue[dvk[k]] !== value[dvk[k]]) return true
                }
                return false
            } else return String(this.defaultValue) !== String(value)
        }
    },
    methods: {
        /**
         * @param {any} value
         * @param {boolean} strict
         */
        setDefaultValue(value, strict = false) {
            const defaultValues = findStoredDefaultValues(this, this.parentForm, 'form')
            //console.log('setDefaultValue', value, this.defaultValue, defaultValues)
            if (defaultValues) {
                if (null !== value && typeof value === 'object') {//clone value
                    value = value instanceof Array ? [].concat(value) : Object.assign({}, value)
                }
                if (!defaultValues[this.defaultValueRef] || strict) {
                    this.defaultValue = defaultValues[this.defaultValueRef] = value
                } else {
                    this.defaultValue = defaultValues[this.defaultValueRef]
                }
                this.resettable = true
            } else this.resettable = false
        },
        /**  */
        resetDefaultValue () {
            this.setDefaultValue(this.computedValue, true)
        },
        /**
         * set value to default
         */
        reset() {
            this.$emit('update:modelValue', this.defaultValue)
        }
    },
    mounted() {
        this.setDefaultValue(this.computedValue)
    }
}

/**
 * @param {Vue} vm
 * @param {string} vmParentName
 * @param {string} tagParentName
 * @returns {object}
 */
const findStoredDefaultValues = function (vm, vmParentName, tagParentName) {
    let defaultValues = null
    //console.log('findStoredDefaultValues', vmParentName, tagParentName)
    if (vmParentName) {
        let $parent = vm.$parent
        //console.log(vmParentName, $parent?.$options?.name)
        while ($parent && $parent?.$options?.name !== vmParentName) $parent = $parent.$parent
        //console.log($parent, $parent && !$parent.$el.getAttribute('data-unresettable'))
        if ($parent && !$parent.$el?.getAttribute('data-unresettable')) {
            defaultValues = $parent.$parent.$defaultValue = $parent.$parent.$defaultValue || _dv()
            defaultValues.$.push(vm)
        }
    }

    if (tagParentName && !defaultValues) {
        let parent = vm.$el?.parentNode
        while (parent && parent.tagName?.toLowerCase() !== tagParentName) parent = parent.parentNode
        //console.log(parent, parent && !parent.getAttribute('data-unresettable'))
        if (parent && !parent.getAttribute('data-unresettable')) {
            defaultValues = parent.$defaultValue = parent.$defaultValue || _dv()
            defaultValues.$.push(vm)
        }
    }

    return defaultValues;
}
/**
 */
const reset = function () {
    this.$.forEach(vm => vm.resetDefaultValue())
}
/**
 */
const restore = function () {
    this.$.forEach(vm => vm.reset())
}
/**
 * @returns {{$: *[], $reset: reset, $restore: restore}}
 * @private
 */
const _dv = function () { return {$: [], reset, restore} }
